exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../clickable.css"), "");

// Module
exports.push([module.id, ".button-root-3SO {\n    background: none;\n    border: 1px solid rgb(var(--color));\n    border-radius: 1.5rem;\n    color: rgb(var(--color));\n    font-size: 0.75rem;\n    font-weight: 600;\n    height: 2rem;\n    min-width: 7.5rem;\n    padding: 0 1rem;\n    transition-duration: 384ms;\n    transition-property: background-color, color;\n    transition-timing-function: var(--venia-anim-standard);\n    --color: var(--venia-text);\n}\n\n.button-filled-2J- {\n    background-color: rgb(var(--color));\n    color: white;\n}\n\n.button-root-3SO:hover {\n    --color: var(--venia-teal);\n}\n\n.button-root-3SO:focus {\n    box-shadow: 0 0 0 2px rgb(var(--venia-teal-light)), 0 0 0.5rem 2px rgba(var(--venia-teal), 0.2);\n    outline: none;\n    transition-duration: 128ms;\n    --color: var(--venia-teal);\n}\n\n.button-root-3SO:active {\n    transition-duration: 128ms;\n    --color: var(--venia-teal-dark);\n}\n\n.button-root-3SO:disabled {\n    pointer-events: none;\n    --color: var(--venia-grey-dark);\n}\n\n/* TODO: allow text to wrap */\n.button-content-1ED {\n    align-items: center;\n    display: inline-grid;\n    grid-auto-flow: column;\n    justify-content: center;\n    justify-items: center;\n}\n\n/* derived classes */\n\n.button-root_lowPriority-Iyr {\n    border-width: 0;\n    text-decoration: underline;\n}\n\n.button-root_lowPriority-Iyr:focus {\n    box-shadow: none;\n}\n\n.button-root_normalPriority-2SA {\n}\n.button-root_highPriority-OdL {\n}\n", ""]);

// Exports
exports.locals = {
	"root": "button-root-3SO " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../clickable.css").locals["root"] + "",
	"filled": "button-filled-2J-",
	"content": "button-content-1ED",
	"root_lowPriority": "button-root_lowPriority-Iyr button-root-3SO " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../clickable.css").locals["root"] + "",
	"root_normalPriority": "button-root_normalPriority-2SA button-root-3SO " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../clickable.css").locals["root"] + "",
	"root_highPriority": "button-root_highPriority-OdL button-root-3SO " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-0-1!../clickable.css").locals["root"] + " button-filled-2J-"
};