import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { distance } from "./generalUtils";
export var storesConfig = {
  I001: {
    cps: "wMQntXdWS2",
    commName: "Milano Cinque Giornate",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/statale/miscusi-milano",
    gmb: "https://g.page/r/CR4L1ffzskt6EBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d12179062-Miscusi_Litta-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Litta",
    city: "Milano",
    reservationName: "milano-cinque-giornate",
    email: "litta@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }]
    }
  },
  I002: {
    cps: "NuObLc2Er7",
    commName: "Milano Cadorna",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/santambrogio/miscusi-cadorna-milano",
    gmb: "https://g.page/r/CcshicbLKY7dEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d15639501-Miscusi-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    city: "Milano",
    reservationName: "milano-cadorna",
    email: "cadorna@miscusi.com",
    label: "Cadorna",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "22:00"
      }],
      "6": [{
        open: "12:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "22:00"
      }]
    }
  },
  I003: {
    cps: "ITF5rECex1",
    commName: "Milano Colonne",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/duomo/miscusi-colonne-milano",
    gmb: "https://g.page/r/CQivhRC91bhfEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d15514445-Miscusi-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Colonne",
    city: "Milano",
    reservationName: "milano-colonne",
    email: "colonne@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "6": [{
        open: "12:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "22:00"
      }]
    }
  },
  I004: {
    cps: "hquADg9r2k",
    commName: "Milano Isola",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/isola/miscusi-isola-milano",
    gmb: "https://g.page/r/CYKOIw643t_AEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d15080743-Miscusi-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Isola",
    city: "Milano",
    reservationName: "milano-isola",
    email: "minniti@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:30"
      }]
    }
  },
  I005: {
    cps: "8eylL7ke4T",
    commName: "Milano Centrale",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/isola/miscusi-centrale-milano",
    gmb: "https://g.page/r/CRASibypA8UwEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d13217749-Miscusi_Stazione_Centrale-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Centrale",
    city: "Milano",
    reservationName: "milano-stazione-centrale",
    email: "camillo@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "18:30",
        close: "23:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "18:30",
        close: "23:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "18:30",
        close: "23:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "18:30",
        close: "23:00"
      }],
      "5": [{
        open: "12:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "23:00"
      }],
      "7": [{
        open: "11:30",
        close: "23:00"
      }]
    }
  },
  I006: {
    cps: "keavsIDFmu",
    commName: "Pavia",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/pavia/miscusi-pav",
    urlDeliveroo: "https://deliveroo.it/it/menu/pavia/centro-storico/miscusi-pavia",
    gmb: "https://g.page/r/CX3q81hWfR-lEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187850-d18719367-Miscusi-Pavia_Province_of_Pavia_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Pavia",
    city: "Pavia",
    reservationName: "pavia-piazza-duomo",
    email: "pv.duomo@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "14:30"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }]
    }
  },
  I008: {
    cps: "r0FccIOdja",
    commName: "Verona",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: false,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/verona/miscusi-ver",
    urlDeliveroo: "https://deliveroo.it/it/menu/verona/centro-storico/miscusi-verona",
    gmb: "https://g.page/r/CWeyTRg9J2jhEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187871-d19702827-Miscusi-Verona_Province_of_Verona_Veneto.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: 99,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Verona",
    city: "Verona",
    reservationName: "verona-porta-nuova",
    email: "vr.portanuova@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }]
    }
  },
  I009: {
    cps: "RVDEtbcefA",
    commName: "Bergamo",
    isReservationActive: true,
    label: "Bergamo",
    city: "Bergamo",
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/bergamo/miscusi-bgy",
    urlDeliveroo: "https://deliveroo.it/it/menu/bergamo/centro-storico/miscusi-bergamo",
    gmb: "https://g.page/r/CTIygnX-BfZGEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187830-d17234037-Miscusi-Bergamo_Province_of_Bergamo_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    reservationName: "bergamo-santa-orsola",
    email: "bergamo@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }]
    }
  },
  I010: {
    cps: "no8JQcPZsB",
    commName: "Torino Carlina",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: false,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/torino/miscusi-tor1",
    urlDeliveroo: "https://deliveroo.it/it/menu/torino/torino-centro/miscusi-torino",
    gmb: "https://g.page/r/CXKGcmg8VACKEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187855-d16869640-Miscusi-Turin_Province_of_Turin_Piedmont.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Carlina",
    city: "Torino",
    reservationName: "torino-piazza-carlina",
    email: "carlina@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:30"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:30"
      }, {
        open: "19:00",
        close: "23:00"
      }]
    }
  },
  I011: {
    cps: "lFYugOakAv",
    commName: "Torino Principi",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: false,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/torino/miscusi-tor1",
    urlDeliveroo: "https://deliveroo.it/it/menu/torino/torino-centro/miscusi-principi-dacaja-torino",
    gmb: "https://g.page/r/CSLYrJ8m8Pg1EBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187855-d21312945-Miscusi-Turin_Province_of_Turin_Piedmont.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Principi",
    city: "Torino",
    reservationName: "torino-porta-susa",
    email: "to.principi@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }]
    }
  },
  I012: {
    cps: "cLI7koxccN",
    commName: "Padova",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 5,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [1, 2, 3, 4, 5],
    urlGlovo: "https://glovoapp.com/it/it/padova/miscusi-pad",
    urlDeliveroo: "https://deliveroo.it/it/menu/padova/centro/miscusi-padova",
    gmb: "https://g.page/r/CSmwvNFNjIp9EBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187867-d23444033-Miscusi-Padua_Province_of_Padua_Veneto.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: 99,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Padova",
    city: "Padova",
    reservationName: "padova-piazza-della-frutta",
    email: "pd.frutta@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "23:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }]
    }
  },
  I013: {
    cps: "WUpw9tyw9O",
    commName: "Bocconi",
    isReservationActive: true,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: true,
    resetBookingOnChange: true,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/milano-bocconi-navigli/bottega-miscusi-bocconi",
    gmb: "https://g.page/r/CU3R2WE38FOwEBM/review",
    tp: "https://www.tripadvisor.com/UserReviewEdit-g187849-d23644001-Miscusi_Bocconi-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "001144",
    kidsCoverSku: "002821",
    breadSku: "001236",
    isFastCasualStore: false,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell CD",
    crossSellCategoryModalFirst: "Cross sell Drinks CD",
    crossSellCategoryModalSecond: "Cross sell Sides CD",
    label: "Bocconi",
    city: "Milano",
    reservationName: "milano-bocconi",
    email: "mi.bocconi@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "2": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "3": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "4": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "5": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "6": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }],
      "7": [{
        open: "12:00",
        close: "15:00"
      }, {
        open: "19:00",
        close: "22:00"
      }]
    }
  },
  I015: {
    cps: "T7xN3qJgP9",
    commName: "Milano Gae Aulenti",
    isReservationActive: false,
    isOnlineOrderActive: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: false,
    resetBookingOnChange: false,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/milano/miscusi/",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/sempione-arco-della-pace/miscusi-porta-nuova-milano",
    gmb: "https://g.page/r/CVXUkDSXDTfREAI/review",
    tp: "https://www.tripadvisor.com/Restaurant_Review-g187849-d26250937-Reviews-Miscusi_Pasta_Fast_Good-Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "",
    kidsCoverSku: "002821",
    breadSku: "",
    isEatInWithQueueCounter: true,
    isFastCasualStore: true,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell FC",
    crossSellCategoryModalFirst: "Cross sell Drinks FC",
    crossSellCategoryModalSecond: "Cross sell Sides FC",
    label: "Gae Aulenti",
    city: "Milano",
    reservationName: "milano-gae-aulenti",
    email: "mi.gaeaulenti@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "17:00"
      }],
      "2": [{
        open: "12:00",
        close: "17:00"
      }],
      "3": [{
        open: "12:00",
        close: "17:00"
      }],
      "4": [{
        open: "12:00",
        close: "17:00"
      }],
      "5": [{
        open: "12:00",
        close: "17:00"
      }],
      "6": [{
        open: "12:00",
        close: "17:00"
      }],
      "7": [{
        open: "12:00",
        close: "17:00"
      }]
    }
  },
  I016: {
    cps: "3TqY0Ivn8J",
    commName: "Assago Milanofiori",
    isReservationActive: false,
    isOnlineOrderActive: true,
    isEatInWithQueueCounter: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: false,
    resetBookingOnChange: false,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/milano-sud-assago-locate-san-giuliano/miscusi-smm/",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/milanofiori/miscusi-assago",
    gmb: "https://g.page/r/Cc2nc3GKOaG4EB0/review",
    tp: "https://www.tripadvisor.com/Restaurant_Review-g780654-d26432882-Reviews-Miscusi_Assago_Milanofiori-Assago_Province_of_Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "default",
    unableOrder: false,
    coverSku: "",
    coverSkuFCD: "002823",
    breadSku: "",
    isFastCasualStore: true,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell FC",
    crossSellCategoryModalFirst: "Cross sell Drinks FC",
    crossSellCategoryModalSecond: "Cross sell Sides FC",
    label: "Assago Milanofiori",
    city: "Milano",
    reservationName: "assago-milanofiori",
    email: "assagomilanofiori@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "21:00"
      }],
      "2": [{
        open: "12:00",
        close: "21:00"
      }],
      "3": [{
        open: "12:00",
        close: "21:00"
      }],
      "4": [{
        open: "12:00",
        close: "21:00"
      }],
      "5": [{
        open: "12:00",
        close: "21:00"
      }],
      "6": [{
        open: "12:00",
        close: "21:00"
      }],
      "7": [{
        open: "12:00",
        close: "21:00"
      }]
    }
  },
  I018: {
    cps: "W8Dsjnm1V4kA",
    commName: "Vicolungo",
    isReservationActive: false,
    isOnlineOrderActive: true,
    isEatInWithQueueCounter: true,
    directToKitchen: true,
    minQuantityReservation: 1,
    maxQuantityReservation: 50,
    isWeekLunchFastCasual: false,
    bookingModalCommsActive: false,
    resetBookingOnChange: false,
    lunchThreshold: "16:00",
    reducedBookingDays: [],
    urlGlovo: "https://glovoapp.com/it/it/milano-sud-assago-locate-san-giuliano/miscusi-smm/",
    urlDeliveroo: "https://deliveroo.it/it/menu/milano/milanofiori/miscusi-assago",
    gmb: "https://g.page/r/Cc2nc3GKOaG4EB0/review",
    tp: "https://www.tripadvisor.com/Restaurant_Review-g780654-d26432882-Reviews-Miscusi_Assago_Milanofiori-Assago_Province_of_Milan_Lombardy.html",
    linkRate: {
      100: "nps",
      0: "gmb",
      0: "tp"
    },
    orderTableActive: true,
    storeViewOrder: "cappelleri",
    unableOrder: false,
    coverSku: "",
    coverSkuFCD: "002823",
    breadSku: "",
    isFastCasualStore: true,
    firstCategory: null,
    crossSellCategoryMiniCart: "Cross sell FC",
    crossSellCategoryModalFirst: "Cross sell Drinks FC",
    crossSellCategoryModalSecond: "Cross sell Sides FC",
    label: "Vicolungo",
    city: "Vicolungo",
    reservationName: "vicolungo",
    email: "vicolungo@miscusi.com",
    maxTimeslotAvailable: "21:30",
    preorderFutureDays: 2,
    hours: {
      "1": [{
        open: "12:00",
        close: "21:00"
      }],
      "2": [{
        open: "12:00",
        close: "21:00"
      }],
      "3": [{
        open: "12:00",
        close: "21:00"
      }],
      "4": [{
        open: "12:00",
        close: "21:00"
      }],
      "5": [{
        open: "12:00",
        close: "21:00"
      }],
      "6": [{
        open: "12:00",
        close: "21:00"
      }],
      "7": [{
        open: "12:00",
        close: "21:00"
      }]
    }
  }
};
export var storesArray = Object.keys(storesConfig).map(key => {
  return _objectSpread({
    id: key
  }, storesConfig[key]);
});
export function getSearchParamsFromUrl() {
  var queryString = window.location.search;
  var urlParams = new URLSearchParams(queryString);
  var result = {};
  for (var [key, value] of urlParams) {
    result[key] = value;
  }
  return result;
}
export function cleanupSessionStorage(keys) {
  keys.forEach(key => {
    window.sessionStorage.removeItem(key);
  });
}
export function getTableData() {
  var searchParams = getSearchParamsFromUrl();
  var orderKeys = ["cps", "cpt", "sales_type", "applyPoints", "coupon", "initialCoversSet", "overrideCoversInCartFromContext"];
  var orderKeysAvailable = Object.keys(searchParams).filter(x => orderKeys.includes(x));
  var filtered = Object.keys(searchParams).filter(key => orderKeysAvailable.includes(key)).reduce((obj, key) => {
    obj[key] = searchParams[key];
    return obj;
  }, {});
  var orderKeysToBeStoredAndRetrieved = ["applyPoints", "coupon"];
  orderKeysToBeStoredAndRetrieved.forEach(key => {
    var storedItem = window.sessionStorage.getItem(key);
    var isKeyInSearchParams = Object.keys(searchParams).includes(key);
    if (storedItem && !isKeyInSearchParams) filtered[key] = storedItem;else if (storedItem === null && isKeyInSearchParams) window.sessionStorage.setItem(key, searchParams[key]);
  });
  if (filtered.cps && filtered.cpt && !filtered.sales_type) {
    filtered["sales_type"] = "CASUALDINING";
  }
  if (Object.keys(filtered).length) {
    return filtered;
  } else {
    return {};
  }
}
export function getStoresConfigByCPS() {
  return Object.keys(storesConfig).reduce(function (acc, key) {
    acc[storesConfig[key].cps] = _objectSpread({
      store: key
    }, storesConfig[key]);
    return acc;
  }, {});
}
export function getStoreIdByCPS(cps) {
  return Object.keys(storesConfig).find(key => storesConfig[key].cps === cps);
}
export function getFilteredSortedStores(stores, choosedPosition) {
  if ((stores === null || stores === void 0 ? void 0 : stores.length) > 0) {
    if (choosedPosition) {
      // Sort by distance
      var newPickupPoints = [...stores].sort((a, b) => distance(choosedPosition.latitude, choosedPosition.longitude, a.latitude, a.longitude) - distance(choosedPosition.latitude, choosedPosition.longitude, b.latitude, b.longitude));
      return newPickupPoints;
    } else {
      return stores;
    }
  }
  return [];
}